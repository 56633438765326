body {
  margin: 0;
  font-family: "Roboto";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 300;
}

@font-face {
font-family: "BauLF-Medium";
src: local("BauLF-Medium"),
 url("./fonts/BauLF-Medium.ttf") format("truetype");
font-weight: regular;
}

@font-face {
  font-family: "Akzidenz-Grotesk BQ";
  src: local("AkzidenzGrotesk-MediumCondAlt"),
   url("./fonts/AkzidenzGrotesk-MediumCondAlt.otf") format("opentype");
  
}

@font-face {
  font-family: "pfdintextpro-regular";
  src: local("pfdintextpro-regular-webfont"),
   url("./fonts/pfdintextpro-regular-webfont.woff") format("woff");
}

@font-face {
  font-family: "pfdintextpro-medium";
  src: local("pfdintextpro-medium-webfont"),
    url("./fonts/pfdintextpro-medium-webfont.woff") format("woff");
}
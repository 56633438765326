@import url(https://fonts.googleapis.com/css?family=Roboto);
@import url(https://netdna.bootstrapcdn.com/font-awesome/4.0.1/css/font-awesome.css);
@import url(https://fonts.googleapis.com/css2?family=Poller+One&display=swap);
body {
  margin: 0;
  font-family: "Roboto";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 300;
}

@font-face {
font-family: "BauLF-Medium";
src: local("BauLF-Medium"),
 url(/static/media/BauLF-Medium.83329d20.ttf) format("truetype");
font-weight: regular;
}

@font-face {
  font-family: "Akzidenz-Grotesk BQ";
  src: local("AkzidenzGrotesk-MediumCondAlt"),
   url(/static/media/AkzidenzGrotesk-MediumCondAlt.f22c2543.otf) format("opentype");
  
}

@font-face {
  font-family: "pfdintextpro-regular";
  src: local("pfdintextpro-regular-webfont"),
   url(/static/media/pfdintextpro-regular-webfont.bac88338.woff) format("woff");
}

@font-face {
  font-family: "pfdintextpro-medium";
  src: local("pfdintextpro-medium-webfont"),
    url(/static/media/pfdintextpro-medium-webfont.e279c358.woff) format("woff");
}

.App {
  
}
body{
  background-color:#010101;
  overflow-x: hidden;
  max-width: 100vw;
 
}
*,body,*::after,*::before{
  box-sizing: border-box;
  
  
}
.video-iframe{
  width: 100vw;
}
.flexrow{
  display: -webkit-flex;
  display: flex;
-webkit-justify-content: center;
        justify-content: center;
-webkit-align-items: flex-start;
        align-items: flex-start; 

width: -webkit-fit-content; 

width: fit-content;
margin: auto;
border: 4px solid #EEEEEE;
border-radius: 2px;
}
.form *{
  margin-bottom: 10px;
}
.poster img{
  max-height: 750px;
  margin: 20px auto;
  max-width: 100%;
  padding: 10px;
  margin-top: 10px;
}
.poster-container {
  width: 100%;
  max-width: 500px;
  -webkit-align-self: center;
          align-self: center;
}

.poster-img {
  width: 100%;
  height: auto;
  max-height: 750px;
  object-fit: contain;
  display: block;
}
.flexunder{
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
  margin-top: 20px;
}

.fleximg{
  display: -webkit-flex;
  display: flex;
 
  -webkit-flex-wrap: wrap ;
 
          flex-wrap: wrap ;
  
  -webkit-justify-content: center;
  
          justify-content: center;
  
}
.fleximg img{
  
  
  max-height: 150px;
  height: 150px;
  min-height: 150px;
  cursor: pointer;
  
}
.middle{
  position: absolute;
  text-align: center;
  z-index: 600;
  

  top:50%;
  left:50%;
  margin-left: -100px;
  top: 50%;
  margin-top: -100px;
  opacity: 0.3;
}
.lg{
  font-family: 'Poller One', cursive !important;
  color: #EEE;
  font-size: 50px;
}
 .flexcol{ display: -webkit-flex; display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}
.pcontent{
  margin: auto;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  
 
}
.hometext{
  font-size: 40px;
  text-align: justify;
   margin: auto;
   font-family: 'Akzidenz-Grotesk BQ';
   font-weight: 100;
   color: #EEEEEE ;
}
.slidecontent{
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  min-width: 100vw;
  margin: auto;
}
.slidecontent p{
  color: #eeeeee;
  opacity: 0.8;
  font-family: "pfdintextpro-regular";
  letter-spacing: 2px;
  line-height: 28px;
  font-size: 16px;
  margin-top: 16px;
  max-width: 68%;
}
.slidecontent p:hover{
  opacity: 1;
}

.slidecontent h1,.slidecontent h2{
  color:  #c1872b;
  font-size: 40px;
  text-transform: uppercase;
  text-align: left;
  margin: auto;
  max-width: 68%;
}
.pcontent h1{
  text-transform: uppercase;
}
.pcontent *{
  text-align: center;
  width: 80vw;
}
.navcontainer{
  height: 80px;
  max-height: 80px;

  background-color: rgba(0, 0, 0, 0);
  color: #EEE;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 999;
  }
  .black{
    
  }
.trailer{
  color: rgb(128, 94, 0);
  width: 200px;

}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.homecon{
  position: fixed;
  top: 20px;
  left: 50px;
  z-index: 1000;
  color: #EEE;
display: -webkit-flex;
display: flex;
max-width:400px;
height:50px;
-webkit-align-items: center;
        align-items: center;
padding:3px;

}
.video-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  background-color: rgba(0,0,0,.7);
  z-index: 2;
  background: rgb(0,0,0);
background: linear-gradient(180deg, rgba(0,0,0,0.49343487394957986) 99%, rgba(12, 12, 12, 0.822) 100%, rgba(0,0,0,0.4990371148459384) 100%);
}
.homecon img{
  width:30px;
  margin-right: 20px;
}
.bar{
  width: 35px;
 height: 5px;
  background-color: #EEE;
  border-radius: 1px;
  margin: 3px;
 

}
.menubtn{
  position: fixed;
  top: 30px;
  right: 30px;
  z-index: 99;

}
.bar:nth-child(1) {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  -webkit-transform-origin: left;
          transform-origin: left;

}
.bar:nth-child(2)  {
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
  -webkit-transform-origin: right;
          transform-origin: right;


}
.bar:nth-child(2) .rotate {
  

}
.nav{

background-color: #010101;
position: fixed;
top: 0;
left: 0;
width: 100vw;
height:100vh;
-webkit-transform: scaleY(0);
        transform: scaleY(0);
transition-property: all;
transition-duration: .5s;
z-index:5000;
color: #EEE;
-webkit-transform-origin: bottom;
        transform-origin: bottom;
opacity: 0;
border: 1px solid rgb(0, 0, 0);

}
.set{
  display: -webkit-flex;
  display: flex;
  grid-gap: 30px;
  gap: 30px;
}
.set > *{
  width: 100%;
}
.adcon{
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  margin: 10%;
}
.adcon *{
  color: #EEE;
}
.adcon button{
  background-color: teal;
  width: 100px;
}
form{
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}
.slide{
  z-index: 9000;
  color: #EEE;
}
.pcontent{
  color: #EEE;

}
.imgs{
  max-height: 250px;
  margin: 10px;
  border: 1px solid teal;
 cursor: pointer;
}
.pcontent p,.pcontent h1,.pcontent h2 {
  max-width: 450px;
  margin-bottom: 5px;
}
.pcontent p{
  /* text-transform: uppercase; */
}
.pcontent h2{
  text-transform: capitalize;
}
.aboutus{
margin-top: 100px;
margin-left: 100px;
background-color: black;
width: 100vw;
min-height: 100vh;
color: #EEE;
z-index: 600;


}
.baulf{
  font-family: "AK";
  color: #EEEEEE !important;
}
.baulf2{
  font-family: "Akzidenz-Grotesk BQ";
  color: #EEEEEE !important;
  font-size: 22px;
  /* text-transform: uppercase; */
}
.hhh{
  text-align: center;
  /* text-transform: uppercase; */
  font-size: 40px;
  z-index: 500;
  color: #EEE;
 
  margin: auto;
}
.workers{
  display: -webkit-flex;
  display: flex;
 
  margin:15px;
  color: #EEE;
}
.rodalform{
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  background-color: rgb(0, 0, 0);
-webkit-align-items: center;
        align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}
.rodalform h4{
  color: teal;
  text-align: center;
}
.rodalform label{
  color: rgb(255, 255, 255);
  font-weight: bold;
}
.rodalform *{
  margin-left: 20px;
  margin-top: 5px;
  width:400px;
  font-family: "pfdintextpro-regular";
  text-transform: uppercase;
  letter-spacing: 2px;
  line-height: 28px;
}
.rodalform button{
  background-color: olive;
color: #EEE;
margin-top: 10px;
width: 200px;
}
.video-background {
  background: #010101;

  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: -99;
  
}
.video-background ::after {
  display: block;
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: rgba(0,0,0,0.75)
}
.vidcon iframe{
  min-width: 100vw;
  min-height: 100vh;
}

.video-foreground,
.video-background iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
}
button:disabled,
button[disabled]{
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;
}
.workers img {
  width: 200px;
  height: 200px;
  margin: 25px;
  border-radius: 50%;
  margin-bottom: 0;
}
.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}
.workers div{
  margin: auto;
}
.hh{
  text-transform: lowercase;
  font-size: 20px;
  text-align: center;
  z-index: 500;
  max-width: 500px;
  margin: auto;
  color: #EEE;
}
.navcontent{
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  margin-right: 90px;
  margin-top: 100px;
  font-family: 'Akzidenz-Grotesk BQ', sans-serif;
  font-size: 40px;

  color: #EEE;
}
.cp-text{

  position: absolute;
  bottom:50px;
  font-size: 14px;
  opacity: 0.8;
   color: #EEE;
   z-index:400;
   text-align: center;
  }
  .cp-text2{

    position: absolute;
    bottom:100px;
    right:120px;
     color: rgba(231, 158, 47, 0.7); text-shadow: 0 1px rgba(255, 255, 255, 0.1);
     z-index:400;
     cursor: pointer;
     
    }
    .gmail{
      color: #c1872b;
      font-weight: 100;
      font-size: 16px;
    }
  .radar {
    -webkit-animation: pulse 5s infinite ease-in-out;
            animation: pulse 5s infinite ease-in-out;
    color: rgba(255, 255, 255, 0.7); text-shadow: 0 1px rgba(255, 255, 255, 0.1);
  }
  .radar img{
    max-width: 70px;
  }
  @-webkit-keyframes pulse {
    0% {
      opacity: 0;
    }
    50% {
opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  @keyframes pulse {
    0% {
      opacity: 0;
    }
    50% {
opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  .fa {
    padding: 10px;
    font-size: 30px;
    
    text-align: center;
    text-decoration: none;
    margin: 15px 2px;
    
    background: #35363800;
    color: #EEE;
  }
  .fa:hover {
    color:  #c1872b;
  }
.ic{
  position: absolute;
  bottom: 5%;
  right: 10px;
  z-index: 99;
}




.nav-content-container{
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  color: #EEE;
  width: 30vw;
}
 .nav-content-container>h1{
  margin: 2px;
  text-align: left;
  color: #EEE;
    font-size: 55px;
  text-transform: uppercase;
  -webkit-transform-origin: bottom;
          transform-origin: bottom;
  -webkit-transform: scaleY(0);
          transform: scaleY(0);
  -webkit-animation-name:scaleup;
          animation-name:scaleup;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-duration: 0.5s;
          animation-duration: 0.5s;

  transform-origin: bottom;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
}
.nav-content-container h1:hover{
  color:  #c1872b  ;
  cursor: pointer;
}

.nav.show{
  -webkit-transform: scaleY(1);
          transform: scaleY(1);
opacity: 1;
}
.App-header {
  background-color: #010101;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #EEE;
}

.App-link {
  color: #61dafb;
}
.flexdivider{
  width: 80%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  margin: 50px auto;
}
.teanrow{
  display: -webkit-flex;
  display: flex;
}
#nav-icon2 {
  width: 60px;
  height: 45px;
  position: fixed;
  top: 20px;
  right: 30px;
  z-index: 5001;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  transition: .5s ease-in-out;
  cursor: pointer;
  -webkit-transform: scale(0.5,0.5);
          transform: scale(0.5,0.5);
}
.vidcon{
  position: relative;
}
.content{
  width: 100vw;
  margin-top: 10px;
  color:#EEE;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  margin-left: 0vw;
  text-align: justify;
  z-index: 600;
  max-width: 600px;

}
.content h2{
  text-transform: uppercase;
  font-size: 29px;
  text-align: justify;
  margin-bottom: 0px;
}
.content p{
  opacity: 0.8;
  text-align: left;
  line-height: 150%;
}
.content p:hover{
  opacity: 1;
}
.projects{
position: relative;
  width: 90vw;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  margin: auto;
color: #EEE;
margin-top: 30px;
}
.linearcont{
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
}
.pcard{
  position: relative;
  margin: 10px 30px;
}
.pcard img{
width: 450px;
-webkit-filter: grayscale(100%);
        filter: grayscale(100%);


}
.pcard h3{
  position: absolute;
bottom: 10px;
left:20px;
color: #EEE;
max-width: 350px;
font-size: 25px;
text-transform: uppercase;
text-align: left;

}
.pcard img:hover{
  -webkit-filter: grayscale(0%);
          filter: grayscale(0%);

}
#myVideo {
  
  right: 0;
  bottom: 0;
  width: 100vw;
  height: auto;
}
.myVideo {
  
  right: 0;
  bottom: 0;
  width: 100vw;
  height: auto;
}
.vidwriteup{
  position: absolute;
  bottom:25%;
  left: 5%;
  text-transform: uppercase;
  color: #eee;
  max-width: 400px;
  z-index: 99;
}
.vidwriteup p{
  color: aqua;
text-align: left;
font-weight: bold;
font-family:  'pfdintextpro-regular';
/* text-transform: uppercase; */
}

.vidwriteup h1{
  font-family: 'Akzidenz-Grotesk BQ';
  font-size: 55px;

}
#nav-icon2 span {
  display: block;
  position: absolute;
  height: 9px;
  width: 50%;
  background: #c1872b ;
  opacity: 1;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  transition: .25s ease-in-out;
}

#nav-icon2 span:nth-child(even) {
  left: 50%;
  border-radius: 0 9px 9px 0;
}

#nav-icon2 span:nth-child(odd) {
  left:0px;
  border-radius: 9px 0 0 9px;
}

#nav-icon2 span:nth-child(1), #nav-icon2 span:nth-child(2) {
  top: 0px;
}

#nav-icon2 span:nth-child(3), #nav-icon2 span:nth-child(4) {
  top: 18px;
}

#nav-icon2 span:nth-child(5), #nav-icon2 span:nth-child(6) {
  top: 36px;
}

#nav-icon2.open span:nth-child(1),#nav-icon2.open span:nth-child(6) {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

#nav-icon2.open span:nth-child(2),#nav-icon2.open span:nth-child(5) {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

#nav-icon2.open span:nth-child(1) {
  left: 5px;
  top: 7px;
}

#nav-icon2.open span:nth-child(2) {
  left: calc(50% - 5px);
  top: 7px;
}

#nav-icon2.open span:nth-child(3) {
  left: -50%;
  opacity: 0;
}

#nav-icon2.open span:nth-child(4) {
  left: 100%;
  opacity: 0;
}

#nav-icon2.open span:nth-child(5) {
  left: 5px;
  top: 29px;
}

#nav-icon2.open span:nth-child(6) {
  left: calc(50% - 5px);
  top: 29px;
}
.slideup{
  display: block;
  z-index: 1;
}

.body {
  background-color: #010101;
  background-image: radial-gradient(#333, #010101);
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  color: #eeeeee;
  text-align: center;
  width: 100vw;
  font-weight: 700;
  overflow: hidden;
  font-family: "pfdintextpro-regular";
  /* text-transform: uppercase; */
  letter-spacing: 2px;
  line-height: 28px;
  margin-top: 30px;
}

#fly-in {
  font-size: 4em;
  margin: 40vh auto;
  height: 20vh; 
  /* text-transform: uppercase; */
}

#fly-in span {
  display: block;
  font-size: .4em;
  opacity: .8;
}

#fly-in div {
 position: fixed; 
  margin: 2vh 0;
  opacity: 0;
  left: 10vw;
  width: 80vw;
  -webkit-animation: switch 32s linear infinite;
          animation: switch 32s linear infinite;
}

#fly-in div:nth-child(2) { -webkit-animation-delay: 4s; animation-delay: 4s}
#fly-in div:nth-child(3) { -webkit-animation-delay: 8s; animation-delay: 8s}
#fly-in div:nth-child(4) { -webkit-animation-delay: 12s; animation-delay: 12s}
#fly-in div:nth-child(5) { -webkit-animation-delay: 16s; animation-delay: 16s}
#fly-in div:nth-child(6) { -webkit-animation-delay: 20s; animation-delay: 20s}
#fly-in div:nth-child(7) { -webkit-animation-delay: 24s; animation-delay: 24s}
#fly-in div:nth-child(8) { -webkit-animation-delay: 28s; animation-delay: 28s}

@-webkit-keyframes switch {
    0% { opacity: 0;-webkit-filter: blur(20px);filter: blur(20px); -webkit-transform:scale(12); transform:scale(12)}
    3% { opacity: 1;-webkit-filter: blur(0);filter: blur(0); -webkit-transform:scale(1); transform:scale(1)}
    10% { opacity: 1;-webkit-filter: blur(0);filter: blur(0); -webkit-transform:scale(.9); transform:scale(.9)}
    13% { opacity: 0;-webkit-filter: blur(10px);filter: blur(10px); -webkit-transform:scale(.1); transform:scale(.1)}
    80% { opacity: 0}
    100% { opacity: 0}
}

@keyframes switch {
    0% { opacity: 0;-webkit-filter: blur(20px);filter: blur(20px); -webkit-transform:scale(12); transform:scale(12)}
    3% { opacity: 1;-webkit-filter: blur(0);filter: blur(0); -webkit-transform:scale(1); transform:scale(1)}
    10% { opacity: 1;-webkit-filter: blur(0);filter: blur(0); -webkit-transform:scale(.9); transform:scale(.9)}
    13% { opacity: 0;-webkit-filter: blur(10px);filter: blur(10px); -webkit-transform:scale(.1); transform:scale(.1)}
    80% { opacity: 0}
    100% { opacity: 0}
}
.slidedown{
  display: none;
}
@-webkit-keyframes scaleup {
  from {
    -webkit-transform: scaleY(0);
            transform: scaleY(0);
  }
  to {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
  }
}
@keyframes scaleup {
  from {
    -webkit-transform: scaleY(0);
            transform: scaleY(0);
  }
  to {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
  }
}
.rodal{
  z-index: 9000;
}
input{
  color: #000000;
}
.rodal-dialogue{
  top: 5vh;
  padding: 5vw;
}
.rodal-mask {
  position: absolute;
  background: black;
}
.teamdesc{
  max-width: 500px;
  margin-right: 7%;
}

.teamdesc *{
  text-align: right;
}
.teamdesc h2{
  color:  #c1872b ;
  margin: 0;
} 
.teanrow{ 
  display: -webkit-flex; 
  display: flex;
  grid-gap: 50px;
  gap: 50px;
 -webkit-flex-wrap: wrap;
         flex-wrap: wrap;
}
.teamflex{
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
 

}
.lk{
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
}
.lk .fa{
  margin: 0px 2px 0px 2px;
  color: #eee ;
}
.lk .fa:hover{
  color: #c1872b ;
}
.lk h4{
  margin: 0;
  text-transform: uppercase;
}
.adminform input,.adminform textarea{
background-color: black;
}
.hib{
  background-color:  #c1872b ; 
  padding: 5px;
  cursor: pointer;
  width: -webkit-fit-content;
  width: fit-content;
  border-radius: 12px;

}

@media (min-aspect-ratio: 16/9) {
  .video-foreground { height: 300%; top: -100%; }
}
@media (max-aspect-ratio: 16/9) {
  .video-foreground { width: 300%; left: -100%; }
}
.slides {
  position: relative;
}
.slides .slick-prev, .slides .slick-next {
  position: absolute;
  top: 50%;
 color:#010101
}
.slides .slick-prev {
  left: 5%;
}
.slides .slick-next {
  right: 5%;
}
.slick-prev:before, .slick-next:before{
  color: #c1872b  !important;
}
element {
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll; 
}

element::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body {
  height: 100%;
}

body {
  font: 16px/1 'pfdintextpro-regular';
  /* text-transform: uppercase; */
  letter-spacing: 2px;
  color: #EEEEEE ;
  background: #010101;
  -webkit-font-smoothing: antialiased;
  font-weight: 200;
}
#filter p:active{
  color: #61dafb;
}
button,
input,

textarea {
  font: Ak;
  font-size: 100%;
  vertical-align: baseline;
  border: 0;
  outline: 0;
  color: #eeeeee;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

textarea {
  overflow: auto;
  vertical-align: top;
  resize: none;
}

[placeholder]::-webkit-input-placeholder { color: #eeeeee; opacity: 0.7; text-transform: uppercase;}
[placeholder]:hover::-webkit-input-placeholder { color: #eeeeee; opacity: 0.7; }
[placeholder]:focus::-webkit-input-placeholder { color: #eeeeee; opacity: 1.0; text-transform: uppercase; -webkit-transform: translateY(-10px); transform: translateY(-10px); -webkit-transition: 0.2s ease-in-out transform; transition: 0.2s ease-in-out transform;}

[placeholder]::-moz-placeholder { color:  #eeeeee;  opacity: 0.7; text-transform: uppercase;}
[placeholder]:hover::-moz-placeholder { color: #eeeeee; opacity: 0.7; }
[placeholder]:focus::-moz-placeholder { color: #eeeeee; opacity: 1.0; text-transform: uppercase; transform: translateY(-10px); -moz-transition: 0.2s ease-in-out transform; transition: 0.2s ease-in-out transform;}

[placeholder]:-ms-input-placeholder { color: #eeeeee;  opacity: 0.7; text-transform: uppercase;}
[placeholder]:hover:-ms-input-placeholder { color: #eeeeee; opacity: 0.7; }
[placeholder]:focus:-ms-input-placeholder { color: #eeeeee; opacity: 1.0; text-transform: uppercase; transform: translateY(-10px); -ms-transition: 0.2s ease-in-out transform; transition: 0.2s ease-in-out transform;}

.input-container {
  height: 40px;
  position: relative;
  width: 65vw;
  margin-bottom: 20px;
}

.textarea-container {
  height: 150px;
  position: relative;
  width: 65vw;
  margin-bottom: 20px;
}

.placeholder {
  color: #eee;
  opacity: 0.7;
  font-family: "pfdintextpro-regular";
  letter-spacing: 2px;
  line-height: 28px;
  font-size: 10px;
  top: 14px; 
  left: 14px;
  pointer-events: none;
  position: absolute;
  -webkit-transform-origin: 0 50%;
          transform-origin: 0 50%;
  transition: color 200ms, -webkit-transform 200ms;
  transition: transform 200ms, color 200ms;
  transition: transform 200ms, color 200ms, -webkit-transform 200ms;
}

.input:not(:-ms-input-placeholder) ~ .placeholder {
  transform: translateY(-25px) translateX(-14px);
}

.input:focus ~ .placeholder,
.input:not(:placeholder-shown) ~ .placeholder {
  -webkit-transform: translateY(-25px) translateX(-14px);
          transform: translateY(-25px) translateX(-14px);
}

.input:hover ~ .placeholder{
  opacity: 1;
}

.input:not(:-ms-input-placeholder) ~ .placeholder {
  color: #eee;
}

.input:not(:placeholder-shown) ~ .placeholder {
  color: #eee;
}

.input:focus ~ .placeholder {
  color: #c1872b;
}

form {
  width: 65vw;
  margin: 10vh auto;
}

input[type="text"] {
  display: block;
  width: 65vw;
  margin: 0 0 20px;
  margin-top: 5px;
  padding: 8px 12px 10px 12px;
  background: rgba(0,0,0,.25);
  color: #eee;
  font-family: "pfdintextpro-regular";
  /* text-transform: uppercase; */
  letter-spacing: 2px;
  line-height: 28px;
  font-size: 10px;
  height: 40px;
  border: 2px solid rgba(230,230,230,0.1);
}

input[type="text"]:hover {
  border: 2px solid rgba(230,230,230,0.2)
}

textarea {
  display: block;
  width: 65vw;
  height: 150px;
  margin: 0 0 20px;
  margin-top: 5px;
  padding: 8px 12px 10px 12px;
  background: rgba(0,0,0,.25);
  background: rgba(0,0,0,.25);
  color: #eee;
  font-family: "pfdintextpro-regular";
  /* text-transform: uppercase; */
  letter-spacing: 2px;
  line-height: 28px;
  font-size: 10px;
  border: 2px solid rgba(230,230,230,0.1)
}

textarea:hover {
  border: 2px solid rgba(230,230,230,0.2)
}

select {
  display: block;
  width: 65vw;
  margin: 0 0 20px;
  padding: 8px 12px 10px 12px;
  background: rgba(0,0,0,.25);
  color: #eee;
  font-family: "pfdintextpro-regular";
  /* text-transform: uppercase; */
  letter-spacing: 2px;
  line-height: 28px;
  margin-top: 5px;
  font-size: 10px;
  height: 40px;
  border: 2px solid rgba(230,230,230,0.1);
}

select:hover {
  border: 2px solid rgba(230,230,230,0.2)
}

.select-dropdown {
  position: relative;
  display: inline-block;
  width: 100%;
  font-family: "pfdintextpro-regular";
  /* text-transform: uppercase; */
  letter-spacing: 2px;
  line-height: 28px;
  

}
 .select-dropdown:last-child {
  margin-right: 0px;
}
 .select-dropdown__button {
  height: 40px;
  width: 100%;
  background-color: #c1872b ;
  color: #010101;
  border: 0px;
  border-radius: 3px;
  cursor: pointer;
  text-align: center;
}
 .select-dropdown__button:focus {
  outline: none;
}
 .select-dropdown__button .zmdi-chevron-down {
  position: absolute;
  right: 10px;
  top: 12px;
}
 .select-dropdown__list {
  position: absolute;
  display: block;
  left: 0;
  right: 0;
  max-height: 300px;
  overflow: auto;
  margin: 0;
  padding: 0;
  border: 1px solid #010101;
  border-top: 0px solid transparent;
  list-style-type: none;
  opacity: 0;
  pointer-events: none;
  -webkit-transform-origin: top left;
          transform-origin: top left;
  -webkit-transform: scale(1, 0);
          transform: scale(1, 0);
  transition: all ease-in-out 0.3s;
  z-index: 2;
}
 .select-dropdown__list.active {
  opacity: 1;
  pointer-events: auto;
  -webkit-transform: scale(1, 1);
          transform: scale(1, 1);
}
 .select-dropdown__list-item {
  display: block;
  list-style-type: none;
  padding: 10px 15px;
  background: #c1872b ;
  border-top: 1px solid #010101;
  font-size: 10px;
  line-height: 1.4;
  cursor: pointer;
  color: #010101;
  text-align: center;
  transition: all ease-in-out 0.3s;
}


.baulf::after {
  content: '';
  display: block;
  width: 0;
  height: 3px;
  background: #c1872b ;
  transition: width .3s;
  border-radius: 2px;
}

.baulf:hover::after {
  width: 100%;
  
}
.baulf2::after {
  content: '';
  display: block;
  width: 0px;
  height: 3px;
  background: #c1872b ;
  transition: width .3s;
  border-radius: 2px;
}

.baulf2:hover::after {
  width: 100%;
}



 .select-dropdown__list-item:hover {
  background-color: #010101;
  color: #EEEEEE;
  transition: all ease-in-out 0.3s;
}
.video-thumb {
  position: absolute;
  top: 0;
  left: 0;
  
  width: 100vw;
  transition: opacity 400ms ease 0ms;
  max-height: 100vh;
}
.tiny {
  -webkit-filter: blur(20px);
          filter: blur(20px);
  transition: visibility 0ms ease 400ms;
}

.radio {
  position: relative;
  display: inline-block;
  margin: 0 20px 20px 0;
  color: #EEEEEE;
}

.radio input[type="radio"] {
  vertical-align: middle;
  opacity: 0;
}

.radio label {
  vertical-align: middle;
  cursor: pointer;
}

.radio input[type="radio"] + label:before {
  content: '';
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: -3px 5px 0 -15px;
  vertical-align: middle;
  font-size: 20px;
  font-family: fontawesome;
  line-height: 19px;
  text-align: center;
  border: 1px solid #c1872b ;
  background: rgba(0,0,0,.25);
}

.radio input[type="radio"] + label:before {
  border-radius: 50%;
}

.radio input[type="radio"]:hover + label:before {
  content: '\f010101';
  font-size: 9px;
  color: #EEEEEE;
}

.radio input[type="radio"]:checked + label:before {
  content: '\f010101';
  font-size: 9px;
  color: #EEEEEE;
}

.radio .sex {
  font: 20px fontawesome;
}
.mdf{
  font-size: 22px;
}
input[type="submit"] {
  display: inline-block;
  width: 150px;
  outline: none;
  font-family: "pfdintextpro-medium";
  text-transform: uppercase;
  font-size: 10px;
  box-sizing: border-box;
  border: 2px solid rgba(230,230,230,0.1);
  letter-spacing: 2px;
  line-height: 2.5em;
  text-transform: uppercase;
  padding: 0 1em;
  height: 40px;
  color: #eeeeee88 ;
  cursor: pointer;
  transition: all .15s ease-in-out;
  background-size: 100% 100%;
  background-position:center;
  background: transparent;
}
input[type="submit"]:focus,
input[type="submit"]:hover {
  background-size: 150% 150%;
  color: #eeeeee;
  border: 2px solid rgba(230,230,230,0.2);
}
input[type="submit"]:active {
  
}
.scroll-to-top {
  position: fixed;
  bottom: 12px;
  left: 90%;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  background: #01010100;
  color: #eeeeee;
  z-index: 1000;
}
.scroll-to-top .icon {
  margin: 10px auto;
  -webkit-animation: scrollTop 0.5s alternate ease infinite;
          animation: scrollTop 0.5s alternate ease infinite;
  display: -webkit-flex;
  display: flex;
  width: 100px;
  color: #EEE;
}
@-webkit-keyframes scrollTop {
  from {
      -webkit-transform: translateY(2px);
              transform: translateY(2px);
  }
  to {
      -webkit-transform: translateY(-1px);
              transform: translateY(-1px);
  }
}
@keyframes scrollTop {
  from {
      -webkit-transform: translateY(2px);
              transform: translateY(2px);
  }
  to {
      -webkit-transform: translateY(-1px);
              transform: translateY(-1px);
  }
}
h1,h2,h3,h4,h5{
  color: #c1872b ;
  font-family: "Akzidenz-Grotesk BQ";
  font-weight: 500;
}

p,a,span{
  color:#EEEEEE ;
}
.slidecontent p{
  color: #EEEEEE ;
}
.slides .slick-prev, .slides .slick-next {
  position: absolute;
  top: 50%;
 
  z-index: 100;
}
/* .golden-btn + .golden-btn { margin-top: 10px; }

.golden-btn {
  display: inline-block;
  outline: none;
  font-family: "Akzidenz-Grotesk BQ";
  font-weight: 500;
  font-size: 16px;
  box-sizing: border-box;
  border: none;
  border-radius: 3px;
  height: 30px;
  
  text-transform: uppercase;
  padding: 0 1em;
  box-shadow: 0 3px 6px rgba(0,0,0,.16), 0 3px 6px rgba(110,80,20,.4),
              inset 0 -2px 5px 1px rgba(139,66,8,1),
              inset 0 -1px 1px 3px rgba(250,227,133,1);
              background-image: linear-gradient(160deg, #c1872b , #c1872b , #c1872b , #c1872b , #c1872b );
              color: rgb(120,50,5);
              text-shadow: 0 2px 2px rgba(250, 227, 133, 1);
  border: 1px solid #a55d07;
  color: rgb(120,50,5);
  text-shadow: 0 2px 2px rgba(250, 227, 133, 1);
  cursor: pointer;
  transition: all .2s ease-in-out;
  background-size: 100% 100%;
  background-position:center;
}
.golden-btn:focus,
.golden-btn:hover {
  background-size: 150% 150%;
  box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23),
                inset 0 -2px 5px 1px #b17d10,
                inset 0 -1px 1px 3px rgba(250,227,133,1);
  border: 1px solid rgba(165,93,7,.6);
  color: rgba(120,50,5,.8);
}
.golden-btn:active {
  box-shadow: 0 3px 6px rgba(0,0,0,.16), 0 3px 6px rgba(110,80,20,.4),
              inset 0 -2px 5px 1px #b17d10,
                inset 0 -1px 1px 3px rgba(250,227,133,1);
} */
.big{
  width: 300px;
}
.slideup h1{
  font-family: 'Akzidenz-Grotesk BQ';
  font-size: 40px;
}
.valatext{
  font-family: "Akzidenz-Grotesk BQ";
  font-weight: 300;
  font-size: 25px;
  cursor: pointer;
}
.nav-content-container>h1{
  font-family: "Akzidenz-Grotesk BQ";
  font-weight: 500;
  font-size: 55px;
}

.btn {
 
  border: 1px solid #a55d07;
  
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  position: relative;
  z-index: 0;
  transition: 1s;
}

.btn::before, .btn::after {
  position: absolute;
  background: #a55d07;
  z-index: -1;
  transition: 1s;
  content: '';
}

.btn::before {
  height: 50px;
  width: 130px;
}

.btn::after {
  width: 150px;
  height: 30px;
}

.noselect {
  -webkit-touch-callout: none;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

.btn:hover::before {
  width: 0px;
  background: #a55d07;
}

.btn:hover::after {
  height: 0px;
  background: #a55d07;
}

.btn:hover {
  background: #a55d07;
}
.bg{
  background-color:'black' !important;
      z-index:8000  !important;
      display:"flex" !important;
      -webkit-flex-direction:'column' !important;
              flex-direction:'column' !important;
      -webkit-justify-content:'center' !important;
              justify-content:'center' !important;
      -webkit-align-items:'center' !important;
              align-items:'center' !important;
}
.slideimg{
  max-width: 800px;
}
.imgslide{
  margin: 0 auto !important;
  width: 500px !important;
  max-width: 500px !important;}



  .teammembers{
    width:500px;
    padding: 2px;
    min-height: 1000px;
    color: #EEE;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
  .teammembers::-webkit-scrollbar { /* WebKit */
    display: none;
  }

  .teampage{
    margin: 0px auto ;
    margin-bottom: 0%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    max-height: 80vh;
    max-width: 80vw;
    position: fixed;
    top: 100px;
    left: 10%;
    right:10%;
    -webkit-align-items: center;
            align-items: center;
  }
  .teamtext{
    width:500px;
  }

  .teamtext h1{
    font-size:55px;
  }

  .teamtext h2{
    text-transform:uppercase;
    line-height: 120%;
  }

  .teamtext p{
    line-height: 150%;
    opacity: 0.8;
  }

  .teamtext p:hover{
    opacity: 1;
  }

  .teamcontent{
    display:'flex';
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    margin: 0 auto;
    text-align: center;
    top: 0;
  }

  .teamcontent h4{
    text-transform:uppercase;
    line-height: 120%;
    font-size: 20px;
  }

 .newimage2 img{
max-height: 350px;

}
    .newimage img{
      max-width: 520px;
      margin: 0px auto auto 0px;
      max-height: 400px;
    }
    .newimage{
      margin: 0px auto auto 0px;
    }
    #filter{
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: space-evenly;
              justify-content: space-evenly;
      grid-gap: 5px;
      gap: 5px;
      width: 80vw;
      margin: 10px auto;
    }
    #filter p{
      font-family: 'Akzidenz-Grotesk BQ';
      font-size: 30px;
      cursor: pointer;
      color: #eee;

    }
    #filter p::after {
      content: '';
      display: block;
      width: 0;
      height: 3px;
      background: #c1872b ;
      transition: width .3s;
      border-radius: 2px;
    }
    
    #filter p:hover::after {
      width: 100%;
      
      
    }
    .storedvideo{
      width: 80%;
      margin: auto;
      
    }
    @media only screen and (max-width: 768px) {
      #filter p{
        font-family: 'Akzidenz-Grotesk BQ';
        font-size: 22px;
        cursor: pointer;
        color: #eee;
      }

      .gmail{
        font-size: 12px;
      }

      .teampage{
        max-height: 200vh;
      }
      .vidwriteup h1{
        font-size: 25px;
      }
      .homecon img {
        width: 30px;
        margin-right: 15px;
        left: 2px ;
        padding:0;
        /* display: none; */
      }
      
      .slideimg{
        max-width: 400px;
      }
      .imgslide{
        margin: 0 auto !important;
        width: 300px !important;
        max-width: 300px !important;}
      
      
      .homecon {
        position: fixed;
        top: 20px;
        left: 30px;
        z-index: 1000;
        color: #EEE;
        display: -webkit-flex;
        display: flex;
        max-width: 400px;
        height: 40px;
        -webkit-align-items: center;
                align-items: center;
      
      }
      .moblepre{
  
        position: absolute; 
        left: 0; 
        top: 100px;
        right: 0; 
        margin-left: auto; 
        margin-right: auto; 
        width: 300px; /* Need a specific value to work */
      }
      .flexunder{
        -webkit-flex-direction: column;
                flex-direction: column;
      }
      .nav-content-container>h1 {
        font-size: 40px;
      }.nav-content-container{
      margin-left: 20px
    }
    .navcontent{
      margin-left: 25px
    }
    .fa{
      width: 40px;
    }
      .content  {
        width: 100vw;
        /* margin-top: 80px; */
        
        display: -webkit-flex;
        
        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column;
        -webkit-justify-content: center;
                justify-content: center;
       
        text-align: justify;
        z-index: 600;
        max-width: calc(100vw-10px);
        margin: 0;
      }
      .nav-content-container{
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column;
       
      
      }
      .navcontent {
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: flex-end;
                justify-content: flex-end;
        /* margin-right: 90px; */
        margin-top: 50px;
      
       
      }
      
      .navcontent{
        display: -webkit-flex;
        display: flex;
        
        margin-right: 90px;
        margin-top: 50px;
      
      
        -webkit-justify-content: flex-start;
      
      
                justify-content: flex-start;
      }
      .aflex{
        display: -webkit-flex;
        display: flex;
        grid-gap :2px;
        gap :2px;
      }
      .cp-text {
        position: absolute;
        bottom: 90px;
        font-size: 12px;
        opacity: 0.8;
        /* right: 50%; */
       
        left: 0; 
        right: 0; 
        margin-left: auto; 
        margin-right: auto; 
        width: 80%; /* Need a specific value to work */
        text-shadow: 0 1px rgb(255 255 255 / 10%);
        z-index: 400;
        text-align: center;
       
        margin: auto;
      }
      .radar {
        -webkit-animation: pulse 3s infinite ease-in-out;
                animation: pulse 3s infinite ease-in-out;
        color: rgba(255, 255, 255, 0.7);
        text-shadow: 0 1px rgb(255 255 255 / 10%);
      }
      .teampage{
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  
  
      }
      .flexdivider{
        -webkit-flex-direction: column;
                flex-direction: column;
      }
      .teamdesc{
        max-width: 400px;
        margin: 0;
      
      
        
       
      }
      .teamdesc *{
        text-align: center;
      }
      .aboutus{
        margin: 0;
        margin-top: 20px;
        margin: 20px auto;
      }.teanrow {
        display: -webkit-flex;
        display: flex;
        /* grid-gap: 50px; */
        grid-gap: 50px;
        gap: 50px;
        -webkit-flex-wrap: wrap;
                flex-wrap: wrap;
        -webkit-justify-content: center;
                justify-content: center;
      }
      .hometext{
        font-size: 14px;
        text-align: justify;
        
      }
      .rodal-close {
        position: absolute;
        cursor: pointer;
        top: 20px !important;
        left: 16px !important;
        width: 20px !important;
        height: 20px !important;
    }
      .slidecontent{
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: center;
                justify-content: center;
        min-width: 100vw;
        margin: auto;
      }
      .slidecontent p{
        width: 82%;
        font-size: 14px;
        max-width: 82%;
      }
      .slidecontent h1,.slidecontent h2{
        color:  #c1872b ;
        font-size: 25px;
        text-transform: uppercase;
        text-align: left;
        max-width: 82%;
        margin: auto;
      }
      .wrapper{
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column;
        -webkit-align-items: center;
                align-items: center;
        max-width: 100vw;
      }
      #myVideo {
        right: 0;
        bottom: 0;
        width: 100vw;
        height: 65vh;
      }
  
      .radar img{
        max-width: 60px;
      }
      input[type="text"], textarea, select {
        width: 65vw;
  
      }
      .scroll-to-top{
        display: none;
      }
      .content{
        margin: 0 15px;
      }
      .flexunder{
        -webkit-flex-direction: column-reverse;
                flex-direction: column-reverse;
      }
      .teampage{
        position: absolute;
      }
      }
      @media only screen and (max-width: 500px) {
        .teampage{
          position: absolute;
        }

        .content{
          margin: 0 15px;
          max-width: 90vw;
        }
        .middle{
          width: 50px;
          margin-left: -25px;
          
          margin-top: -25px;
        }
        .homecon {
          position: fixed;
          top: 20px;
          left: 10%;
          z-index: 1000;
          color: #EEE;
          display: -webkit-flex;
          display: flex;
          max-width: 400px;
          height: 40px;
          -webkit-align-items: center;
                  align-items: center;
          /* margin-left: -54px; */
        }
        .homecon h1{
          display: none;
        }
        .newimage img{

          max-width: 300px;
          margin:auto
        }

        .flexunder{
          -webkit-flex-direction: column-reverse;
                  flex-direction: column-reverse;
        }
      }

.element {
  overflow-y: scroll !important;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.element::-webkit-scrollbar { /* WebKit */
  display: none;
}
    
